import React from 'react';

const GamePage = () => {
  return (
    <div className="game-page">
      <h1>Welcome to Nexus RPG!</h1>
      <p>This is the main game interface. More content coming soon!</p>
    </div>
  );
};

export default GamePage;
