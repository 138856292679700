import React, { useState } from 'react';
import './login.css';

const AuthPage = () => {
  const [view, setView] = useState('login'); // Default view is 'login'

  // State and functions for form inputs
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Handlers for form submissions
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    // API call to /api/login
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        alert('Login successful');
        // Handle login success (redirect, etc.)
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    // API call to /api/register
    try {
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (response.ok) {
        alert('Registration successful. Please log in.');
        setView('login');
      } else {
        const errorData = await response.json();
        alert(`Registration failed: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error registering:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    // API call to /api/forgot-password
    try {
      const response = await fetch('/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });

      if (response.ok) {
        alert('Password reset instructions have been sent.');
        setView('login');
      } else {
        alert('Failed to send reset instructions.');
      }
    } catch (error) {
      console.error('Error with password reset:', error);
      alert('An error occurred. Please try again.');
    }
  };

  // Render Login Form
  const renderLogin = () => (
    <div className="auth-container">
      <h2>Login</h2>
      <form onSubmit={(e) => handleLoginSubmit(e)}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
      <p>
        Forgot your password?{' '}
        <span className="auth-link" onClick={() => setView('forgot')}>
          Click here
        </span>
      </p>
      <p>
        Don't have an account?{' '}
        <span className="auth-link" onClick={() => setView('register')}>
          Register now
        </span>
      </p>
    </div>
  );

  // Render Register Form
  const renderRegister = () => (
    <div className="auth-container">
      <h2>Register</h2>
      <form onSubmit={(e) => handleRegisterSubmit(e)}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          required
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="submit">Register</button>
      </form>
      <p>
        Already have an account?{' '}
        <span className="auth-link" onClick={() => setView('login')}>
          Login here
        </span>
      </p>
    </div>
  );

  // Render Forgot Password Form
  const renderForgotPassword = () => (
    <div className="auth-container">
      <h2>Forgot Password</h2>
      <form onSubmit={(e) => handleForgotPasswordSubmit(e)}>
        <input
          type="text"
          placeholder="Username or Email"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <button type="submit">Send Reset Instructions</button>
      </form>
      <p>
        Remember your password?{' '}
        <span className="auth-link" onClick={() => setView('login')}>
          Login here
        </span>
      </p>
    </div>
  );

  return (
    <div className="auth-page">
      {view === 'login' && renderLogin()}
      {view === 'register' && renderRegister()}
      {view === 'forgot' && renderForgotPassword()}
    </div>
  );
};

export default AuthPage;
